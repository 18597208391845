import React, { useState } from 'react';
import Button from '@atlaskit/button';

import { Context } from '../../../content';
import { ProductCard } from './product-card';
import { translatableText } from '../../utils/translatable-text';
import analytics from '../../utils/analytics';
import { AnalyticsMetadata } from '../../utils/analytics/types';
import ChevronDown from '@atlaskit/icon/glyph/chevron-down';
import ChevronUp from '@atlaskit/icon/glyph/chevron-up';

interface ProductListProps {
    productList: Context[];
    analyticsMetadata: AnalyticsMetadata;
    renderV2?: boolean;
}

export const ProductCardsGroup = ({
    productList,
    analyticsMetadata,
    renderV2
}: ProductListProps) => {
    const [shortList, setShortList] = useState(true);

    const handleOnClick = () => {
        setShortList(!shortList);

        analytics.trackEvent('toggle product card group', {
            // @ts-ignore
            event: 'toggled',
            eventComponent: 'product-card-group',
            actionSubjectId: 'link',
            category: 'product',
            action: 'toggled',
            label: 'toggle-product-card-group'
        });
    };

    const v2ClassSuffix = renderV2 ? '-v2' : '';
    const productToggleText = shortList
        ? translatableText.seeAllOtherProducts.text
        : translatableText.showLessProducts.text;

    const productToggleTextIcon = shortList ? (
        <ChevronDown label="" size="large" />
    ) : (
        <ChevronUp label="" size="large" />
    );

    return (
        <div>
            <ul className={`product-list-wrapper${v2ClassSuffix}`}>
                {productList?.length > 0 &&
                    (shortList ? productList.slice(0, 5) : productList).map(
                        (product) => {
                            const {
                                productNameReference,
                                icon,
                                iconAlternate,
                                id,
                                deploymentUrls,
                                deployment
                            } = product;
                            if (deploymentUrls && icon) {
                                return (
                                    <ProductCard
                                        title={productNameReference.productName}
                                        icon={
                                            iconAlternate
                                                ? iconAlternate.url
                                                : icon.url
                                        }
                                        key={id}
                                        id={id}
                                        url={
                                            deployment === 'Cloud'
                                                ? deploymentUrls.cloud
                                                : deploymentUrls.server
                                        }
                                        analyticsMetadata={analyticsMetadata}
                                        renderV2={renderV2}
                                    />
                                );
                            }
                            return [];
                        }
                    )}
            </ul>
            <Button
                className="products-toggle__button"
                appearance="link"
                onClick={handleOnClick}
            >
                {renderV2 ? (
                    <span className="products-toggle__text">
                        {productToggleText}
                        {productToggleTextIcon}
                    </span>
                ) : (
                    productToggleText
                )}
            </Button>
        </div>
    );
};
