import React, { useEffect } from 'react';
// @ts-ignore
import Button from '@atlaskit/button';
import ButtonV2 from '../button-v2';
import { AtlasLink, track } from '../atlas-link';
import './contact-list-item.less';
import { translatableText } from '../../utils/translatable-text';
import { useSupportFormUrls } from '../../hooks/use-support-form-urls';
import { useAuth } from '../../providers/auth-providers';
import analytics from '../../utils/analytics';
import { AnalyticsMetadata } from '../../utils/analytics/types';

interface ContactProps {
    supportFormUrls?: SupportFormUrls;
    analyticsMetadata?: AnalyticsMetadata;
}

export interface SupportFormUrls {
    authenticated: string | null;
    unauthenticated: string | null;
}

export const ContactCtaBlock = ({
    supportFormUrls,
    analyticsMetadata
}: ContactProps) => {
    const supportFormLinks = useSupportFormUrls(supportFormUrls);
    const { isAuthenticated } = useAuth();

    const { href: supportFormHref, text: supportFormText } =
        supportFormLinks[
            isAuthenticated ? 'authenticated' : 'needsAuthentication'
        ];
    return (
        <div className={`contact-block`}>
            <div className={`contact-block--inner`}>
                <h2 className={`contact-block__header`}>
                    {translatableText.notFindingTheHelpYouNeed.text}
                </h2>
                <ButtonV2
                    size="small"
                    variant="secondary"
                    href={supportFormHref}
                    ariaLabel="contact support"
                    onClick={() => {
                        analytics.trackEvent('contact support', {
                            // @ts-ignore
                            event: 'clicked',
                            eventComponent: 'contact-support',
                            actionSubjectId: 'link',
                            category: 'contact',
                            action: 'clicked',
                            label: 'contact support',
                            ...analyticsMetadata
                        });
                    }}
                >
                    {supportFormText}
                </ButtonV2>
                {supportFormUrls && supportFormUrls.unauthenticated && (
                    <div className="margin-top-medium">
                        <Button
                            className='margin-top-medium"'
                            appearance="link"
                            href={supportFormUrls.unauthenticated}
                            onClick={() =>
                                track(
                                    supportFormUrls?.unauthenticated,
                                    'Contact Support Without Logging In',
                                    'contactSupportWithoutLoggingIn'
                                )
                            }
                        >
                            {
                                translatableText.supportRequestWithoutLoggingIn
                                    .text
                            }
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export const ContactListItem = ({ supportFormUrls }: ContactProps) => {
    const supportFormLinks = useSupportFormUrls(supportFormUrls);
    const { isAuthenticated } = useAuth();

    const { href: supportFormHref } =
        supportFormLinks[
            isAuthenticated ? 'authenticated' : 'needsAuthentication'
        ];

    useEffect(() => {
        if (isAuthenticated) {
            analytics.trackEvent('goToContactFormButtonLoaded', {
                // @ts-ignore
                event: 'loaded',
                eventComponent: 'goToContactFormButtonLoaded',
                action: 'loaded'
            });
        }
    }, [isAuthenticated]);
    return (
        <AtlasLink
            dataName="contactButton"
            href={supportFormHref}
            data-testid="contact-button"
            text={translatableText.contactV2.text}
        />
    );
};
