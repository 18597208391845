import { sl_tr_start, sl_tr_end } from './smartling-tags';

sl_tr_start();

export const translatableText = {
    sl_translate: 'text',
    viewSupportRequests: { text: 'View Support Requests' },
    viewRequests: { text: 'View requests' },
    viewAccount: { text: 'View Account' },
    manageAccount: { text: 'Manage account' },
    switchAccount: { text: 'Switch account' },
    namedContacts: { text: 'Manage named contacts' },
    support: { text: 'Support' },
    account: { text: 'Account' },
    logOut: { text: 'Log out' },
    askTheCommunity: { text: 'Ask the Community' },
    atlassianSupport: { text: 'Atlassian Support' },
    resources: { text: 'Resources' },
    contactSupport: { text: 'Contact Support' },
    notFindingTheHelpYouNeed: {
        text: 'Not finding the help you need?'
    },
    logInToContactSupport: {
        text: 'Log in to contact support'
    },
    logInToAccount: { text: 'Log in to account' },
    contactV2: { text: 'Contact us' },
    signIn: { text: 'Sign in' },
    questionsDiscussionsAndArticles: {
        text: 'Questions, discussions, and articles'
    },
    cloud: { text: 'Cloud' },
    seeAll: { text: 'See all' },
    server: { text: 'Data Center' },
    products: { text: 'Products' },
    notAccurate: { text: "It wasn't accurate" },
    notClear: { text: "It wasn't clear" },
    notRelevant: { text: "It wasn't relevant" },
    yes: { text: 'Yes' },
    no: { text: 'No' },
    provideFeedbackAboutThisArticle: {
        text: 'Provide feedback about this article'
    },
    onThisPage: { text: 'On this page' },
    search: { text: 'Search' },
    kbHomeSearchPlaceholder: {
        text: 'Search troubleshooting and how-to articles...'
    },
    searchPlaceholderV2: { text: "Tell us what you're looking for" },
    noSearchResults: { text: 'No results found for' },
    browse: { text: 'Browse' },
    cancel: { text: 'Cancel' },
    visit: { text: 'Visit' },
    orTrySearchingOurDocumentation: {
        text: ' or try searching our documentation:'
    },
    browseSupport: { text: 'Browse Support' },
    browseByProduct: { text: 'Browse by product' },
    browseResources: { text: 'Browse resources' },
    partnerPortal: { text: 'Partner Portal' },
    viewPage: { text: 'View Page' },
    editThisTopic: { text: 'Edit this Topic' },
    globalHeroHeadline: {
        text: 'Resources for users and administrators'
    },
    heroBannerHeadline: {
        text: 'How can we help?'
    },
    heroBannerSubheader: {
        text: 'Find answers via Atlassian support resources'
    },
    community: { text: 'Community' },
    privacyPolicy: { text: 'Privacy Policy' },
    noticeAtCollection: { text: 'Notice at Collection' },
    termsOfUse: { text: 'Terms of Use' },
    terms: { text: 'Terms' },
    impressum: { text: 'Impressum' },
    security: { text: 'Security' },
    getStarted: { text: 'Get started' },
    documentation: { text: 'Documentation' },
    knowledgeBase: { text: 'Knowledge base' },
    viewTopics: { text: 'View topics' },
    viewTopic: { text: 'View topic' },
    exploreMore: { text: 'Explore more' },
    versions: { text: 'Versions' },
    additionalHelp: { text: 'Additional Help' },
    stillNeedHelp: { text: 'Still need help?' },
    hereForYou: { text: 'The Atlassian Community is here for you.' },
    wasThisHelpful: { text: 'Was this helpful?' },
    showMore: { text: 'Show more' },
    sortBy: { text: 'Sort by' },
    supportRequestWithoutLoggingIn: {
        text: 'Submit support request without logging in'
    },
    upNext: { text: 'Up next' },
    moreInThisGuide: { text: 'More in this guide' },
    helpfulTopics: { text: 'Other helpful links' },
    seeAllOtherProducts: {
        text: 'See all other products'
    },
    showLessProducts: {
        text: 'Show less products'
    },
    multipleVersionsProducts: {
        text: 'Applies to multiple versions & products'
    },
    resultText: {
        text: 'Showing startCount-endCount of totalCount for "searchTerm"'
    },
    noResultText: {
        text: 'No results found for "searchTerm"'
    },
    resetButton: {
        text: 'Clear filters'
    },
    noFiltersApplied: {
        text: 'No filters applied'
    },
    yourPrivacyChoices: {
        text: 'Your Privacy Choices'
    },
    accessibility: {
        text: 'Accessibility'
    }
};

sl_tr_end();
